import React from 'react';
import styled from 'styled-components';
import loadable from "@loadable/component"
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import '../../styles/globals.css';
import { Link, graphql } from 'gatsby';
import { MetaComponent } from '../../components';
import {
  Button,
  SassyText,
  Title,
  Section,
  Text,
  Divider,
  SectionWrapper,
} from '../../components/core';
import { useMixpanel } from '../../context/MixpanelContext';
import useHindiLangFont from '../../hooks/useHindiLangFont';
import { LeadFormProvider } from '../../context/LeadFormContext';

import Bullet from '../../assets/images/bullet.svg';

const Footer = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../../components/LeadForm"));
const Navbar = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Navbar,
});

const Coverage = loadable(() => import("../../components/Coverage"));
const HelpSection = loadable(() => import("../../components/HelpSection"));
const PolicyHeader = loadable(() => import("../../components/PolicyHeader"));
const VideoSection = loadable(() => import("../../components/VideoSection"));

const BSUS = () => {
  const {hindiBold, hindiBlack, hindiRegular} = useHindiLangFont()
  const {t} = useTranslation(['bsus', 'common'])
  const mixpanel = useMixpanel()
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (mxEventName = "") => {
    if (mxEventName) mixpanel.track(mxEventName)
    handleQuoteModalOpen()
  }

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <MetaComponent
        title="Bharat Sookshma Udyam Suraksha (BSUS) Policy - Verak"
        description="Protect your business from pressing risks like fire, lightning, explosion and natural disasters. Get a quote."
        image="bsus"
        url="fire/bsus"
      />
      <Navbar active="products" />
      <PolicyHeader
        acronym="BSUS"
        title={
          <>
            <SassyText className={hindiBlack}>{t('HeroSection.Title')}</SassyText> (BSUS)
          </>
        }
        content={
          <span className={hindiRegular}>
            <Trans i18nKey="HeroSection.Desc1">
              Bharat Sookshma Udyam Suraksha (BSUS) is a
              <strong className={hindiBold}> Fire and Allied Perils Insurance product </strong>
              available in India that protects SMEs against loss due to fire,
              explosion, natural calamities, malicious activity, and much more.
            </Trans>
          </span>
        }
        subcontent={
          <span style={{fontWeight: "700"}} className={hindiRegular}>
            <Trans i18nKey="HeroSection.Desc2">
              You can opt for a BSUS policy if your business’s total insurable
              assets at 
              <SassyText>
                any one location are worth less than or equal to ₹5 Crore
              </SassyText>
            </Trans>
          </span>
        }
      />
      <VideoSection />
      <Coverage type="BSUS" />
      <Section background="#ffffff">
        <SectionWrapper>
          <Title className={hindiBlack}>
            <Trans i18nKey="WhyBSUS.Title">
              Why opt for a <SassyText> BSUS </SassyText> policy?
            </Trans>
          </Title>
          <Left>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t("WhyBSUS.Protection.Title")}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                    className={hindiRegular}
                  >
                    {t("WhyBSUS.Protection.Desc")}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t("WhyBSUS.RiskCoverage.Title")}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                    className={hindiRegular}
                  >
                    {t("WhyBSUS.RiskCoverage.Desc")}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t("WhyBSUS.AdditionalCovers.Title")}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                    className={hindiRegular}
                  >
                    {t("WhyBSUS.AdditionalCovers.Desc")}
                  </Text>
                </li>
              </ul>
            </Container>
            <Text fontSize="16px" mfontSize="16px" className={hindiRegular}>
              <Trans i18nKey="WhyBSUS.LearnAboutBSUS">
                Learn about
                <strong className={hindiBold}> Bharat Sookshma Udyam Suraksha (BSUS) </strong>
                in detail 
                <a href="https://blog.verak.in/product-explainers-bharat-sookshma-udyam-suraksha-bsus-policy-amp/">
                  <SassyText className={hindiRegular}>HERE</SassyText>
                </a>
              </Trans>
            </Text>
            <Text
              fontSize="16px"
              style={{ margin: '40px 0px' }}
              mfontSize="16px"
              className={hindiRegular}
            >
              <Trans i18nKey="WhyBSUS.OtherOptionsText">
                If your business’s total insurable assets at 
                <strong className={hindiBold}> any one location are worth more than ₹5 Crore </strong> ,
                you may want to consider opting for a 
              </Trans>
              <Link to="/fire/sfsp">
                <SassyText type="secondary" className={hindiRegular}>
                  {t('SFSPFullForm')} (SFSP)
                </SassyText>
              </Link>
              , {t('WhyBSUS.Or')} {t('WhyBSUS.A')}{' '}
              <Link to="/fire/blus">
                <SassyText type="secondary" className={hindiRegular}>
                {t('BLUSFullForm')} (BLUS)
                </SassyText>
              </Link> {''}
              <Trans i18nKey="WhyBSUS.InsurancePolicy">
                Insurance policy
              </Trans>!
            </Text>
            <Button wide label={t('CTALabels.GetAQuote')} onClick={() => handleGetQuoteClick("Clicked-Down - 'Get a Quote' - BSUS LP")} className={hindiBold} />
          </Left>
        </SectionWrapper>
      </Section>
      <HelpSection />
      <Divider />
      <Footer />
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["bsus", "index", "common", "navbar", "footer", "getQuoteModal", "helpSection", "coverageSection", "videoSection"]
        }, 
        language: {eq: $language}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Left = styled.div`
  text-align: left;

  @media screen and (max-width: 768px) {
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: start;
  margin: 20px 0px;

  ul {
    margin-top: 40px;
  }

  .bullet {
    margin-left: -20px;
    margin-right: 35px;
  }

  li {
    color: #999999;
    margin-left: 24px;
    margin-bottom: 24px;
    list-style: none;
  }
`;

export default BSUS;
